import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";
import ScrollToTop from "../../components/ScrollToTop";
import Breadcrumb from "../../components/Breadcrumb";
import ContentAccordion from "../../components/ContentAccordion";
import SignUp from "../../components/SignUp";
import data from "../../data/resources.json";

const Resources = props => (
  <ScrollToTop>
    <DocumentTitle title="More Resources">
      <div id="resources">
        <Helmet>
          {/* Open Graph Data */}
          <meta property="og:url" content="http://prepare.admission.ucla.edu/more-resources/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="More Resources" />
          <meta property="og:description" content="Find additional college prep resources for 8th–11th graders that offer tips on financial aid, academic resources and more." />
          <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="314" />
          <meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
          <meta property="fb:app_id" content="1067749600064544" />

          {/* Twitter Card Data */}
		      <meta name="twitter:card" content="summary_large_image" />
		      <meta name="twitter:site" content="@UCLAAdmission" />

          <link rel="canonical" href="http://prepare.admission.ucla.edu/more-resources/" />
        </Helmet>
        <Breadcrumb>
          <span className="breadcrumb-heading">More Resources</span>
        </Breadcrumb>
        <section className="resources-accordions">
          <ContentAccordion accordionItems={data.resources}>
            {resourcesAccordionItemBody}
          </ContentAccordion>
        </section>
        <SignUp />
      </div>
    </DocumentTitle>
  </ScrollToTop>
);

const resourcesAccordionItemBody = ({ links }) => (
  <div>
    <ul>
      {links.map((link, index) => {
        const { text, url } = link;
        return (
          <li key={index}>
            <a href={url} className="gtm-outbound-link-resources" target="_blank" rel="noopener noreferrer">{text + " \u29C9"}</a>
          </li>
        );
      })}
    </ul>
  </div>
);

export default Resources;
