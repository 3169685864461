import React from "react";
import { Link } from "@reach/router";
import icon from "../data/grades/topics-icons.js";

const Nav = props => (
  <div className="nav">
    {props.links.map(link => (
      <Link
        className={`gtm-nav-component` + (link.highlight ? ' highlight' : '')}
        key={link.text}
        to={link.path}
      >
        <p className="link-content">
          {link.icon && icon(link.icon)}
          <span className="text" dangerouslySetInnerHTML={{ __html: link.text }} />
          <span className="arrow" />
        </p>
      </Link>
    ))}
  </div>
);

export default Nav;
