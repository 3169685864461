import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";
import Breadcrumb from "../../components/Breadcrumb";
import ContentAccordion from "../../components/ContentAccordion";
import NotFound from "./NotFound";
import SignUp from "../../components/SignUp";
import grades from "../../data/grades/grades-breadcrumbs.json";
import metaTags from "../../data/meta-tags.json";

class WhenToDoStuff extends React.Component {
  state = {
    data: null,
    isFirstLoad: true
  };

  async componentDidMount() {
    let data;
    try {
      data = await import(`../../data/grades/when-to-do-stuff/${this.props.grade}.json`);
    } catch (e) {
      data = null;
    }
    this.setState({ data, isFirstLoad: false });
  }

  render() {
    const { grade } = this.props;
    const { data, isFirstLoad } = this.state;

    if (isFirstLoad) return null;
    if (!data) return <NotFound />;

    return (
      <DocumentTitle title={`Prepare for College – ${grades[grade]} Tips Timeline | UCLA`}>
        <div id="when-to-do-stuff">
          <Helmet>
            {/* Open Graph Data */}
            <meta property="og:url" content={`http://prepare.admission.ucla.edu/${grade}/timeline/`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Prepare for College –${" "}${grades[grade]} Tips Timeline | UCLA`} />
            <meta property="og:description" content={`${metaTags[grade]["timeline"]["description"]}`} />
            <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="314" />
            <meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
            <meta property="fb:app_id" content="1067749600064544" />

            {/* Twitter Card Data */}
  		      <meta name="twitter:card" content="summary_large_image" />
  		      <meta name="twitter:site" content="@UCLAAdmission" />
            
            <link rel="canonical" href={`http://prepare.admission.ucla.edu/${grade}/timeline/`} />
          </Helmet>
          <Breadcrumb>
            <span className="breadcrumb-heading-small">
              {grades[grade]}
            </span>
            <br />
            <span className="breadcrumb-heading">When To Do Stuff</span>
          </Breadcrumb>
          <section className="when-to-do-stuff-accordions">
            <ContentAccordion accordionItems={data.seasons}>
              {whenToDoStuffAccordionItemBody}
            </ContentAccordion>
          </section>
          <SignUp />
        </div>
      </DocumentTitle>
    );
  }
}

const whenToDoStuffAccordionItemBody = ({ title, seasonContent }) => {
  return seasonContent.map(({ headline, description, links }) => (
    <div
      key={headline}
      className={`accordion-child ${title.toLowerCase()}-border`}
    >
      <p className="headline">{headline}</p>
      <p>{description}</p>
      <div>
        {links ? (
          <ul>
            {links.map((link, index) => {
              const { buttonUrl, buttonText } = link;
              return (
                <li key={index}>
                  <a href={buttonUrl} className="button gtm-outbound-link-whentodostuff" target="_blank" rel="noopener noreferrer">
                    {buttonText}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  ));
};

export default WhenToDoStuff;
