import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";
import ScrollToTop from "../../components/ScrollToTop";
import Nav from "../../components/Nav";
import Breadcrumb from "../../components/Breadcrumb";
import SignUp from "../../components/SignUp";
import NotFound from "./NotFound";
import grades from "../../data/grades/grades-breadcrumbs.json";
import topics from "../../data/grades/topics-nav.json";
import metaTags from "../../data/meta-tags.json";

const Grades = props => {
  if (!grades[props.grade]) return <NotFound />;
  return (
    <ScrollToTop>
      <DocumentTitle title={`Prepare for College – ${grades[props.grade]} Tips | UCLA`}>
        <div id="grades">
          <Helmet>
            {/* Open Graph Data */}
            <meta property="og:url" content={`http://prepare.admission.ucla.edu/${props.grade}/`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Prepare for College –${" "}${grades[props.grade]} Tips | UCLA`} />
            <meta property="og:description" content={`${metaTags[props.grade]["description"]}`} />
            <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="314" />
            <meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
            <meta property="fb:app_id" content="1067749600064544" />

            {/* Twitter Card Data */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@UCLAAdmission" />
            
            <link rel="canonical" href={`http://prepare.admission.ucla.edu/${props.grade}/`} />
          </Helmet>
          <Breadcrumb>
            <span className="breadcrumb-heading">{grades[props.grade]}</span>
          </Breadcrumb>
          <section className="grades-nav">
            <Nav links={topics} />
          </section>
          <SignUp />
        </div>
      </DocumentTitle>
    </ScrollToTop>
  );
};

export default Grades;
