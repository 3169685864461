import React from "react";
const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer>
    <a
      className="gtm-logo-footer-link-outbound"
      href="http://www.admission.ucla.edu"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="logo"
        src="/images/logo-admission.svg"
        alt="UCLA Undergraduate Admission"
      />
    </a>
    <p className="address">
      1147 Murphy Hall, Los Angeles, CA 90095-1436 <br />
      University of California &copy; {currentYear}&nbsp;
      <a
        className="gtm-footer-link-outbound"
        href="https://www.universityofcalifornia.edu"
        target="_blank"
        rel="noopener noreferrer"
      >
        UC Regents
      </a>
      &nbsp;|&nbsp;
      <a
        className="gtm-footer-link-outbound"
        href="http://www.ucla.edu/terms-of-use/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      &nbsp;|&nbsp;
      <a
        className="gtm-footer-link-outbound"
        href="https://www.admission.ucla.edu/prepare/help.htm"
        target="_blank"
        rel="noopener noreferrer"
      >
        Help
      </a>
    </p>
  </footer>
);

export default Footer;
