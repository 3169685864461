import React from "react";
import { Router } from "@reach/router";
import Home from "./components/Home";
import Grades from "./components/Grades";
import Topics from "./components/Topics";
import Resources from "./components/Resources";
import WhenToDoStuff from "./components/WhenToDoStuff";
import Posters from "./components/Posters";
import NotFound from "./components/NotFound";

const MainContent = () => (
  <Router>
    <Home path="/" />
    <Grades path=":grade" />
    <Topics path=":grade/:topic" />
    <WhenToDoStuff path=":grade/timeline" />
    <Resources path="more-resources" />
    <Posters path="poster-art" />
    <NotFound default />
  </Router>
);

export default MainContent;
