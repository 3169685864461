import React from "react";
import {
  IconSummer,
  IconFall,
  IconWinter,
  IconSpring,
  IconAnytime
} from "../../svg/Icons";

const icon = (icon) => {
  const icons = {
    "icon-summer": <IconSummer />,
    "icon-fall": <IconFall />,
    "icon-winter": <IconWinter />,
    "icon-spring": <IconSpring />,
    "icon-anytime": <IconAnytime />
  };

  return icons[icon];
};

export default icon;
