import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";

const NotFound = () => (
  <DocumentTitle title="404">
    <section id="NotFound" className="dark-gradient">
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div className="container">
        <picture>
          <source
            srcSet="/images/404/text-bubble-404-xlarge2x.png"
            media="(min-width: 768px)"
            />
          <img
            id="thought-bubble"
            src="/images/404/text-bubble-404-large2x.png"
            alt="Oops. Something is wrong."
          />
          <img
            className="ie11 ie-dependent"
            src="/images/404/text-bubble-404-xlarge2x.png"
            alt="Oops. Something is wrong."
            />
        </picture>
        <div id="hero-copy">
          <h1>The page you're looking for doesn't exist.</h1>
          <p>
            Check your URL or return to <a href="/"> the homepage.</a>
          </p>
        </div>
        <img id="smiley" src="/images/404/smiley.png" alt="" />
      </div>
    </section>
  </DocumentTitle>
);

export default NotFound;
