import React from "react";

// Use https://svgr.now.sh/ to convert SVG files to React components

// TOPICS ICONS
// Each SVG must have unique ID's on path and mask tags so they can be mapped to their corresponding topic
// Remove fill attribute from the final path tag
// Add className="icon-color" to the final path tag
// See IconTarget for an example
const IconTarget = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>196A4704-34FE-41A6-A053-9725D247AE9C</title>
    <defs>
      <path
        d="M27.605 15.572c-.6.147-1.218-.063-1.872-.597l3.474-3.474h1.587c-.547 1.405-1.724 3.712-3.189 4.072zm-8.872 4.989A2.982 2.982 0 0 0 17 20c-1.655 0-3 1.345-3 3 0 1.654 1.345 3 3 3 1.654 0 3-1.346 3-3 0-.646-.21-1.242-.56-1.734l1.413-1.412C21.558 20.716 22 21.802 22 23c0 2.758-2.244 5-5 5-2.757 0-5-2.242-5-5 0-2.756 2.243-5 5-5 1.198 0 2.285.442 3.146 1.148l-1.413 1.413zM19 23c0 1.102-.898 2-2 2-1.102 0-2-.898-2-2 0-1.103.898-2 2-2 .37 0 .712.107 1.01.282l-.766.766c-.08-.02-.159-.049-.244-.049a1 1 0 1 0 1 1c0-.085-.029-.163-.049-.244l.765-.765c.176.297.284.64.284 1.01zm6 0a7.97 7.97 0 0 1-2.317 5.621c-.011.01-.026.015-.037.026-.01.01-.015.024-.024.037A7.978 7.978 0 0 1 17 31a7.978 7.978 0 0 1-5.623-2.317c-.009-.012-.013-.027-.024-.037-.01-.01-.024-.014-.037-.024A7.97 7.97 0 0 1 9 23c0-4.41 3.588-8 8-8 2.026 0 3.873.764 5.285 2.01l-1.415 1.413A5.97 5.97 0 0 0 17 17.002c-3.309 0-6 2.691-6 6 0 3.308 2.691 6 6 6 3.308 0 6-2.692 6-6a5.975 5.975 0 0 0-1.422-3.87l1.413-1.413A7.954 7.954 0 0 1 25.002 23zm-.573-10.606c.175-.707.81-1.346 1.573-1.881v2.78l-.974.973c-.535-.653-.745-1.27-.599-1.871zm4.073-1.601l-1.5 1.5v-2.39c.53-.284 1.06-.524 1.5-.695v1.585zm3.404-.09a.502.502 0 0 0-.404-.204h-2v-2a.5.5 0 0 0-.65-.476c-.193.06-4.748 1.512-5.393 4.132-.233.945.055 1.896.85 2.83l-1.316 1.316A8.955 8.955 0 0 0 17 14.001c-4.964 0-9 4.037-9 9a8.95 8.95 0 0 0 2.302 5.99l-2.157 2.157a.5.5 0 0 0 .707.707l2.156-2.157A8.951 8.951 0 0 0 17 32a8.95 8.95 0 0 0 5.99-2.303l2.157 2.157a.502.502 0 0 0 .707 0 .5.5 0 0 0 0-.707l-2.156-2.157A8.947 8.947 0 0 0 26 23a8.953 8.953 0 0 0-2.302-5.992l1.326-1.325c.721.612 1.451.938 2.182.938.213 0 .425-.026.639-.078 2.62-.646 4.073-5.2 4.133-5.395a.502.502 0 0 0-.075-.444z"
        id="target-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="target-b" fill="#fff">
        <use xlinkHref="#target-a" />
      </mask>
      <g mask="url(#target-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconPerson = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>712AE7EF-4D67-4BA8-933E-F220DBB646F3</title>
    <defs>
      <path
        d="M26.5 28.503c.102 0 .203.031.29.093l2.167 1.546-.925-2.467a.5.5 0 0 1 .148-.56l1.938-1.615H28a.5.5 0 0 1-.46-.304l-1.035-2.424-1.046 2.426A.5.5 0 0 1 25 25.5h-2.118l1.938 1.615a.5.5 0 0 1 .148.56l-.925 2.467 2.167-1.546a.498.498 0 0 1 .29-.093m3.5 3.498a.498.498 0 0 1-.29-.093l-3.21-2.291-3.21 2.29a.503.503 0 0 1-.595-.01.502.502 0 0 1-.163-.573l1.375-3.668-2.727-2.272a.5.5 0 0 1 .32-.884h3.171l1.377-3.195a.5.5 0 0 1 .459-.302h.001c.2 0 .38.12.459.304L28.33 24.5h3.17a.5.5 0 0 1 .32.884l-2.727 2.272 1.375 3.669a.502.502 0 0 1-.468.676M13.375 14.5c0-.081.007-.161.01-.242.469.159.955.242 1.49.242 1.374 0 2.881-.653 3.734-1.55.797 1.109 1.738 1.636 2.932 1.636.264 0 .544-.035.834-.085 0 3.032-2.019 5.499-4.5 5.499s-4.5-2.467-4.5-5.5m4.5-5.5c2.204 0 4.039 1.947 4.423 4.507l-.018.002c-1.453.281-2.352-.194-3.205-1.687l-.455-.795-.423.812c-.461.885-2.013 1.661-3.322 1.661-.5 0-.94-.109-1.371-.285C13.979 10.801 15.756 9 17.875 9m5.5 5.5c0-3.584-2.468-6.5-5.5-6.5s-5.5 2.916-5.5 6.5c0 2.338 1.054 4.385 2.625 5.53v2.118l-4.678 1.67A3.509 3.509 0 0 0 8 27.114V30h11v-1H9v-1.886c0-1.054.666-2 1.658-2.354L16 22.853v-2.25a4.728 4.728 0 0 0 1.875.397c.753 0 1.471-.181 2.125-.506V22.5h1v-2.658c1.433-1.174 2.375-3.13 2.375-5.342"
        id="person-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="person-b" fill="#fff">
        <use xlinkHref="#person-a" />
      </mask>
      <g mask="url(#person-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconPrep = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>7F33E41B-3C25-44AC-BF99-6AE848E26CE7</title>
    <defs>
      <path
        d="M9 31h22V9H9v22zm22.5 1h-23a.5.5 0 0 1-.5-.5v-23a.5.5 0 0 1 .5-.5h23a.5.5 0 0 1 .5.5v23a.5.5 0 0 1-.5.5zm-18-14.5a.502.502 0 0 1-.354-.146l-2-2a.502.502 0 0 1 .708-.708l1.646 1.647 4.646-4.647a.502.502 0 0 1 .708.708l-5 5a.502.502 0 0 1-.354.146m0 9.5a.502.502 0 0 1-.354-.146l-2-2a.502.502 0 0 1 .708-.708l1.646 1.647 4.646-4.647a.502.502 0 0 1 .708.708l-5 5A.502.502 0 0 1 13.5 27m15-10h-8a.5.5 0 0 1 0-1h8a.5.5 0 0 1 0 1m0 9h-8a.5.5 0 0 1 0-1h8a.5.5 0 0 1 0 1"
        id="prep-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="prep-b" fill="#fff">
        <use xlinkHref="#prep-a" />
      </mask>
      <g mask="url(#prep-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconCalculator = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>3CCD7F54-3327-4E48-AC8F-43DB2B36A018</title>
    <defs>
      <path
        d="M31 19H20V9h8.5c1.379 0 2.5 1.122 2.5 2.5V19zm0 9.5c0 1.379-1.121 2.5-2.5 2.5H20V20h11v8.5zM19 19H9v-7.5C9 10.122 10.122 9 11.5 9H19v10zm0 12h-7.5A2.503 2.503 0 0 1 9 28.5V20h10v11zm9.5-23h-17C9.57 8 8 9.57 8 11.5v17c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5v-17C32 9.57 30.43 8 28.5 8zm-18 6.5a.5.5 0 0 0 .5.5h2v2a.5.5 0 0 0 1 0v-2h2a.5.5 0 0 0 0-1h-2v-2a.5.5 0 0 0-1 0v2h-2a.5.5 0 0 0-.5.5m1.146 13.354a.498.498 0 0 0 .707 0l1.648-1.646 1.646 1.646a.498.498 0 0 0 .707 0 .5.5 0 0 0 0-.707L14.707 25.5l1.646-1.646a.5.5 0 0 0-.707-.707L14 24.793l-1.647-1.646a.5.5 0 0 0-.707.707l1.646 1.646-1.646 1.646a.5.5 0 0 0 0 .707M23 25h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0 0 1m5-11h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1m-5 13h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0 0 1"
        id="calculator-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="calculator-b" fill="#fff">
        <use xlinkHref="#calculator-a" />
      </mask>
      <g mask="url(#calculator-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconCalendar = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>DA28674C-5951-4821-8656-8D4F45DE6356</title>
    <defs>
      <path
        d="M20.5 23H25v-3h-4.5v3zm0 4H25v-3h-4.5v3zM15 27h4.5v-3H15v3zm0-4h4.5v-3H15v3zm14.5-3a.5.5 0 0 0 0-1H26v-1.5a.5.5 0 0 0-1 0V19h-4.5v-1.5a.5.5 0 0 0-1 0V19H15v-1.5a.5.5 0 0 0-1 0V19h-3.5a.5.5 0 0 0 0 1H14v3h-3.5a.5.5 0 0 0 0 1H14v3h-3.5a.5.5 0 0 0 0 1H14v1.5a.5.5 0 0 0 1 0V28h4.5v1.5a.5.5 0 0 0 1 0V28H25v1.5a.5.5 0 0 0 1 0V28h3.5a.5.5 0 0 0 0-1H26v-3h3.5a.5.5 0 0 0 0-1H26v-3h3.5zM9 31h22V16H9v15zm3-20v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V11h8v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V11h3v4H9v-4h3zm1 1h2V9h-2v3zm12 0h2V9h-2v3zm6.5-2H28V8.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V10h-8V8.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V10H8.5a.5.5 0 0 0-.5.5v21a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5v-21a.5.5 0 0 0-.5-.5z"
        id="calendar-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="calendar-b" fill="#fff">
        <use xlinkHref="#calendar-a" />
      </mask>
      <g mask="url(#calendar-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconResources = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Icons/Line-item/Resource/Default</title>
    <defs>
      <path
        d="M25 27.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1 0-1h13a.5.5 0 0 1 .5.5zm4-3a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1 0-1h17a.5.5 0 0 1 .5.5zm0-3a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h6a.5.5 0 0 1 .5.5zm-7-3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM31 31H9V10h2v11.5a.5.5 0 0 0 .854.354l3.646-3.647 3.646 3.647a.504.504 0 0 0 .545.108A.5.5 0 0 0 20 21.5V10h11v21zM12 9h7v11.293l-3.146-3.147a.502.502 0 0 0-.708 0L12 20.293V9zm19.5 0H20v-.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5V9H8.5a.5.5 0 0 0-.5.5v22a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5v-22a.5.5 0 0 0-.5-.5z"
        id="resources-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="resources-b" fill="#fff">
        <use xlinkHref="#resources-a" />
      </mask>
      <g mask="url(#resources-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconPoster = props => (
  <svg
    width={24}
    height={24}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Icons/Line-item/Art/Default</title>
    <defs>
      <path
        d="M11 31h19V9H11v22zM30.5 8h-20a.5.5 0 0 0-.5.5v23a.5.5 0 0 0 .5.5h20a.5.5 0 0 0 .5-.5v-23a.5.5 0 0 0-.5-.5zM28 11v14.806l-5-1.666v-1.361c.371-.285.916-.952.991-2.429.417-.277.655-.895.655-1.497 0-.334-.076-.631-.22-.86a1.092 1.092 0 0 0-.245-.276c.219-.514.54-1.394.304-2.338C24.139 13.992 22.391 13.5 21 13.5c-1.239 0-2.761.39-3.323 1.456-.453-.021-.794.108-1.02.383-.511.625-.102 1.653.199 2.366a1.067 1.067 0 0 0-.268.29c-.151.233-.23.535-.23.875 0 .595.237 1.206.651 1.481.075 1.476.62 2.143.991 2.428v1.361l-5 1.666V11h15zM13 29v-2.14l5.658-1.885A.502.502 0 0 0 19 24.5v-2a.537.537 0 0 0-.315-.465C18.678 22.032 18 21.704 18 20c0-.276-.165-.486-.441-.486-.115-.078-.27-.56-.167-.893.037-.121.07-.121.108-.121a.5.5 0 0 0 .5-.5c0-.16-.064-.311-.191-.611-.421-.992-.437-1.319-.383-1.412-.001 0 .104-.063.474.013a.5.5 0 0 0 .585-.369c.162-.649 1.22-1.121 2.515-1.121 1.295 0 2.353.472 2.515 1.121.181.725-.135 1.436-.324 1.861-.099.224-.159.359-.159.518 0 .276.254.503.53.503.022.015.082.12.084.336.003.344-.134.626-.146.661a.5.5 0 0 0-.5.5c0 1.693-.669 2.028-.665 2.028A.501.501 0 0 0 22 22.5v2c0 .215.138.406.342.475L28 26.86V29H13zm-.5 1h16a.5.5 0 0 0 .5-.5v-19a.5.5 0 0 0-.5-.5h-16a.5.5 0 0 0-.5.5v19a.5.5 0 0 0 .5.5z"
        id="poster-a"
      />
    </defs>
    <g transform="translate(-8 -8)" fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path d="M0 0h40v40H0z" />
      <mask id="poster-b" fill="#fff">
        <use xlinkHref="#poster-a" />
      </mask>
      <g mask="url(#poster-b)">
        <path d="M0 0h40v40H0z" />
        <path className="icon-color" fillRule="nonzero" d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

const IconSummer = props => (
  <svg width={62} height={31} {...props}>
    <title>Icons/Season/Line/Duo/Summer</title>
    <g
      className="icon-color"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="#FFF"
    >
      <path d="M21.896 15.7a6.195 6.195 0 1 1-12.392 0 6.195 6.195 0 1 1 12.391 0zM16.113 7.852V6.2M9.917 9.917L8.678 8.678M7.852 16.113H6.2M16.113 23.548V25.2M21.896 21.07l1.239 1.239M23.548 16.113H25.2M21.896 9.917l1.239-1.239M9.917 21.07l-1.239 1.239" />
      <g>
        <path d="M52.896 15.7a6.195 6.195 0 1 1-12.392 0 6.195 6.195 0 1 1 12.391 0zM47.113 7.852V6.2M40.917 9.917l-1.239-1.239M38.852 16.113H37.2M47.113 23.548V25.2M52.896 21.07l1.239 1.239M54.548 16.113H56.2M52.896 9.917l1.239-1.239M40.917 21.07l-1.239 1.239" />
      </g>
    </g>
  </svg>
);

const IconFall = props => (
  <svg width={62} height={31} {...props}>
    <title>Icons/Season/Line/Duo/Fall</title>
    <g
      className="icon-color"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="#FFF"
    >
      <path d="M17.665 13.156L8.393 22.43" />
      <path
        className="icon-stroke"
        d="M23.461 7.361S21.013 29.645 9.043 21.778C1.177 9.808 23.46 7.361 23.46 7.361z"
        strokeLinejoin="round"
      />
      <path className="icon-stroke" d="M14.961 19.725h-3.864v-3.864M17.28 16.248h-2.705v-2.705M18.825 13.157h-1.16v-1.16" />
      <g>
        <path d="M48.665 13.156l-9.272 9.274" />
        <path
          className="icon-stroke"
          d="M54.461 7.361s-2.448 22.284-14.418 14.417C32.177 9.808 54.46 7.361 54.46 7.361z"
          strokeLinejoin="round"
        />
        <path className="icon-stroke" d="M45.961 19.725h-3.864v-3.864M48.28 16.248h-2.705v-2.705M49.825 13.157h-1.16v-1.16" />
      </g>
    </g>
  </svg>
);

const IconWinter = props => (
  <svg width={62} height={31} {...props}>
    <title>Icons/Season/Line/Duo/Winter</title>
    <g
      className="icon-color"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#FFF"
    >
      <path d="M25.2 12.738L23.081 9.16l-5.263 2.961V6.2H13.58v5.921l-5.262-2.96L6.2 12.738l5.262 2.962L6.2 18.66l2.119 3.578 5.262-2.962V25.2h4.237v-5.924l5.263 2.962L25.2 18.66 19.937 15.7zM56.2 12.738L54.081 9.16l-5.263 2.961V6.2H44.58v5.921l-5.262-2.96-2.119 3.577 5.262 2.962-5.262 2.96 2.119 3.578 5.262-2.962V25.2h4.237v-5.924l5.263 2.962L56.2 18.66 50.937 15.7z" />
    </g>
  </svg>
);

const IconSpring = props => (
  <svg width={62} height={31} {...props}>
    <title>Icons/Season/Line/Duo/Spring</title>
    <g

      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#FFF"
    >
      <path className="icon-color" d="M18.591 16.132c0 1.67-1.294 3.023-2.891 3.023-1.598 0-2.891-1.354-2.891-3.023 0-1.67 1.293-3.023 2.891-3.023 1.597 0 2.891 1.353 2.891 3.023z" />
      <path d="M24.454 13.062c-.636-2.041-2.733-3.158-4.686-2.495a3.696 3.696 0 0 0-.717.336c.054-.264.085-.537.085-.817 0-2.147-1.665-3.886-3.718-3.886s-3.717 1.74-3.717 3.886c0 .28.03.553.083.816a3.698 3.698 0 0 0-.717-.335c-1.953-.663-4.05.455-4.684 2.495-.635 2.041.433 4.234 2.387 4.897.254.088.512.142.768.17-.191.18-.37.382-.526.608-1.207 1.737-.838 4.167.822 5.43 1.66 1.261 3.986.876 5.193-.861.157-.226.286-.465.391-.712.104.247.234.486.391.712 1.207 1.737 3.533 2.121 5.193.86 1.663-1.262 2.029-3.692.822-5.429a3.853 3.853 0 0 0-.526-.608c.256-.028.513-.082.768-.17 1.953-.663 3.022-2.856 2.388-4.897z" />
      <g>
        <path className="icon-color" d="M49.591 16.132c0 1.67-1.294 3.023-2.891 3.023-1.598 0-2.891-1.354-2.891-3.023 0-1.67 1.293-3.023 2.891-3.023 1.597 0 2.891 1.353 2.891 3.023z" />
        <path d="M55.454 13.062c-.636-2.041-2.733-3.158-4.686-2.495a3.696 3.696 0 0 0-.717.336c.054-.264.085-.537.085-.817 0-2.147-1.665-3.886-3.718-3.886s-3.717 1.74-3.717 3.886c0 .28.03.553.083.816a3.698 3.698 0 0 0-.717-.335c-1.953-.663-4.05.455-4.684 2.495-.635 2.041.433 4.234 2.387 4.897.254.088.512.142.768.17-.191.18-.37.382-.526.608-1.207 1.737-.838 4.167.822 5.43 1.66 1.261 3.986.876 5.193-.861.157-.226.286-.465.391-.712.104.247.234.486.391.712 1.207 1.737 3.533 2.121 5.193.86 1.663-1.262 2.029-3.692.822-5.429a3.853 3.853 0 0 0-.526-.608c.256-.028.513-.082.768-.17 1.953-.663 3.022-2.856 2.388-4.897z" />
      </g>
    </g>
  </svg>
);

const IconAnytime = props => (
  <svg width={62} height={31} {...props}>
    <title>Icons/Season/Line/Duo/Anytime</title>
    <g className="icon-color" fill="none" fillRule="evenodd" stroke="#FFF">
      <path d="M15.7 24.336c1.24 0 .413.864 2.891.864 2.479 0 4.957-5.182 4.957-8.636 0-3.455-2.065-6.046-4.544-6.046-2.478 0-2.478.864-3.304.864-.826 0-.826-.864-3.304-.864-2.479 0-4.544 2.591-4.544 6.046 0 3.454 2.478 8.636 4.957 8.636 2.478 0 1.652-.864 2.891-.864z" />
      <path
        d="M15.287 11.382V9.655c0-.95-.743-1.728-1.652-1.728h-1.652"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.94 9.223c2.052 0 3.717-1.74 3.717-3.887-2.053 0-3.718 1.74-3.718 3.887z"
        strokeLinejoin="round"
      />
      <g>
        <path d="M46.7 24.336c1.24 0 .413.864 2.891.864 2.479 0 4.957-5.182 4.957-8.636 0-3.455-2.065-6.046-4.544-6.046-2.478 0-2.478.864-3.304.864-.826 0-.826-.864-3.304-.864-2.479 0-4.544 2.591-4.544 6.046 0 3.454 2.478 8.636 4.957 8.636 2.478 0 1.652-.864 2.891-.864z" />
        <path
          d="M46.287 11.382V9.655c0-.95-.743-1.728-1.652-1.728h-1.652"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.94 9.223c2.052 0 3.717-1.74 3.717-3.887-2.053 0-3.718 1.74-3.718 3.887z"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export {
  IconTarget,
  IconPerson,
  IconPrep,
  IconCalculator,
  IconCalendar,
  IconResources,
  IconPoster,
  IconSummer,
  IconFall,
  IconWinter,
  IconSpring,
  IconAnytime
};
