import React from "react";
import Nav from "./Nav";
import grades from "../data/grades/grades-nav.json";

const Journey = () => (
  <section id="journey">
    <div className="h2">Start Your Journey</div>
    <Nav links={grades} />
  </section>
);

export default Journey;
