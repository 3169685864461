import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";
import ScrollToTop from "../../components/ScrollToTop";
import Breadcrumb from "../../components/Breadcrumb";
import Download from "../../components/Download";
import SignUp from "../../components/SignUp";
import posters from "../../data/posters.json";

const Posters = () => (
	<ScrollToTop>
		<DocumentTitle title="Poster Art">
			<div id="posters">
				<Helmet>
					{/* Open Graph Data */}
		      <meta property="og:url" content="http://prepare.admission.ucla.edu/poster-art/" />
		      <meta property="og:type" content="website" />
		      <meta property="og:title" content="Poster Art" />
		      <meta property="og:description" content="Check out and print fun poster images about what students get credit for doing at UCLA." />
		      <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
					<meta property="og:image:width" content="600" />
					<meta property="og:image:height" content="314" />
					<meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
					<meta property="fb:app_id" content="1067749600064544" />

					{/* Twitter Card Data */}
		      <meta name="twitter:card" content="summary_large_image" />
		      <meta name="twitter:site" content="@UCLAAdmission" />

          <link rel="canonical" href="http://prepare.admission.ucla.edu/poster-art/" />
		    </Helmet>
				<Breadcrumb>
					<span className="breadcrumb-heading">Poster Art</span>
				</Breadcrumb>
				<section className="poster-content">
					<p className="poster-copy">Download or <a href="https://connect.admission.ucla.edu/register/?id=61109a4d-71da-4ac9-96d3-4091dc0ebb9e" target="_blank" rel="noopener noreferrer" class="gtm-footer-link-outbound">order</a> posters about what students get credit for doing at UCLA.</p>
					<Download items={posters} />
				</section>
				<SignUp />
			</div>
		</DocumentTitle>
	</ScrollToTop>
);

export default Posters;
