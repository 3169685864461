import React, { Component } from "react";

class Video extends Component {
  render() {
    return (
      <iframe
        title="video"
        width="720px"
        height="405px"
        src="https://www.youtube.com/embed/XZXrSB5dbZI?enablejsapi=1"
        style={{
          maxWidth: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          margin: "auto",
          top: "50%",
          transform: "translateY(-50%)"
        }}
      />
    );
  }
}

export default Video;
