import React from "react";
import Helmet from "react-helmet";
import DocumentTitle from "react-document-title";
import Breadcrumb from "../../components/Breadcrumb";
import ContentAccordion from "../../components/ContentAccordion";
import SignUp from "../../components/SignUp";
import NotFound from "./NotFound";
import grades from "../../data/grades/grades-breadcrumbs.json";
import metaTags from "../../data/meta-tags.json";

class Topics extends React.Component {
  state = {
    data: null,
    isFirstLoad: true
  };

  async componentDidMount() {
    let data;
    try {
      data = await import(`../../data/grades/topics/${this.props.grade}.json`);
    } catch (e) {
      data = null;
    }
    this.setState({ data, isFirstLoad: false });
  }

  render() {
    const { grade, topic } = this.props;
    const { data, isFirstLoad } = this.state;

    if (isFirstLoad) return null;
    if (!data || !data[topic]) return <NotFound />;

    return (
      <DocumentTitle title={`Prepare for College – ${grades[grade]} Tips on ${data[topic].title} | UCLA`}>
        <div id="topics">
          <Helmet>
            {/* Open Graph Data */}
            <meta property="og:url" content={`http://prepare.admission.ucla.edu/${grade}/${topic}/`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Prepare for College –${" "}${grades[grade]} Tips on ${data[topic].title} | UCLA`} />
            <meta property="og:description" content={`${metaTags[grade][topic]["description"]}`} />
            <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="314" />
            <meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
            <meta property="fb:app_id" content="1067749600064544" />

            {/* Twitter Card Data */}
  		      <meta name="twitter:card" content="summary_large_image" />
  		      <meta name="twitter:site" content="@UCLAAdmission" />

            <link rel="canonical" href={`http://prepare.admission.ucla.edu/${grade}/${topic}/`} />
          </Helmet>
          <Breadcrumb>
            <span className="breadcrumb-heading-small">
              {grades[grade]}
            </span>
            <br />
            <span className="breadcrumb-heading">{data[topic].title}</span>
          </Breadcrumb>
          <section className="topics-accordions">
            <ContentAccordion accordionItems={data[topic].accordions}>
              {gradeAccordionItemBody}
            </ContentAccordion>
          </section>
          <SignUp />
        </div>
      </DocumentTitle>
    );
  }
}

const gradeAccordionItemBody = ({ description, links }) => (
  <React.Fragment>
    <p>{description}</p>
    <React.Fragment>
      {links ? (
        <React.Fragment>
          {links.map((link, index) => {
            const { buttonUrl, buttonText } = link;
            return (
              <div key={index}>
                <a href={buttonUrl} className="button gtm-outbound-link-topics" target="_blank" rel="noopener noreferrer">
                  {buttonText}
                </a>
              </div>
            );
          })}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  </React.Fragment>
);

export default Topics;
