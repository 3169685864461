import React from "react";

const Download = props => (
  <div className="poster-links">
    {props.items.map((item, index) => (
      <div key={item.altText} className={`poster-item-${index + 1} poster-item`}>
        <img src={item.image} alt={item.altText} />
        <p>
          <a className="gtm-download-pdf-poster" data-poster-name={item.posterTitle} href={item.url} download>
            Download PDF
          </a>
        </p>
      </div>
    ))}
  </div>
);

export default Download;
