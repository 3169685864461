import React from "react";
import Nav from "./Nav";
import links from "../data/prefooter-nav.json";

const Prefooter = () => (
  <div id="prefooter">
    <Nav links={links} />
  </div>
);

export default Prefooter;
