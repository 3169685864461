import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import Header from "./components/Header";
import MainContent from "./routes/Router";
import Footer from "./components/Footer";
import "./css/main.css";
import browserUpdate from "browser-update";

class App extends Component {
  render() {
    // detect outdated browsers
    browserUpdate({
      required: {
        e: 16,
        i: 11,
        f: 54,
        o: 44,
        o_a: 46,
        s: 10.1,
        c: 58,
        samsung: 11.8,
        uc: 11.8,
        ios: 10.3
      },
      insecure: true,
      reminder: 0
    });

    return (
      <DocumentTitle title="College Prep Tips | UCLA">
        <div id="app">
          <Header />
          <MainContent />
          <Footer />
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
