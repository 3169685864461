import React, { Component } from "react";
import PropTypes from "prop-types";
import Lightbox from "lightbox-react";
import VideoIframe from "../components/VideoIframe";
import studentImages from "../data/student-images.json";

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      imageNumber: 0,
      cycleCount: 1
    };
  }

  cycleImage() {
    if ((this.props.availableImages !== this.state.imageNumber + 1) && (this.state.cycleCount <= this.props.maxCycleCount)) {
      this.setState(prevState => ({
        imageNumber: prevState.imageNumber + 1
      }));
    } else {
      this.setState(prevState => ({
        imageNumber: 0,
        cycleCount: prevState.cycleCount + 1
      }));
    }
    document.getElementById("students").src =
      studentImages[this.state.imageNumber];
  }

  componentDidMount() {
    this.interval = setInterval(() => this.cycleImage(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { isOpen } = this.state;
    return (
      <section id="hero" className="dark-gradient">
        <div className="container">

        <picture>
          <source
            srcSet="/images/text-bubble-gray-xlarge2x.png"
            media="(min-width: 768px)"
            />
          <img
            id="thought-bubble"
            src="/images/text-bubble-gray-large2x.png"
            alt="Did somebody say college?"
            />
          <img
            className="ie11 ie-dependent"
            src="/images/text-bubble-gray-xlarge2x.png"
            alt="Did somebody say college?"
            />
        </picture>
        {/* <div className="title-holder">
           <h1>Did somebody say college?<span><img className="emojis" src="/images/admission-emotions.png" alt="College Admission Emotions"/></span></h1>
         </div> */}
          <div id="hero-copy">
            <h2>Let us help you get there.</h2>
            <button
              className="gtm-students-stories-button"
              type="button"
              onClick={() => this.setState({ isOpen: true })}
            >
              See Real Students&rsquo; Stories
            </button>
          </div>
          <img id="students" src={studentImages[0]} alt="" />
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={VideoIframe}
            onCloseRequest={() => this.setState({ isOpen: false })}
            enableZoom={false}
          />
        )}
      </section>
    );
  }
}

Hero.propTypes = {
  availableImages: PropTypes.number.isRequired,
  maxCycleCount: PropTypes.number.isRequired
};

Hero.defaultProps = {
  availableImages: studentImages.length,
  maxCycleCount: 2
};

export default Hero;
