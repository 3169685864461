import React from "react";
import { Link } from "@reach/router";

const Breadcrumb = props => (
  <Link className="breadcrumb-wrapper gtm-back-arrow-breadcrumb" to="../">
    <div className="breadcrumb">
      <div className="back-arrow" />
      <div className="breadcrumb-title">{props.children}</div>
    </div>
  </Link>
);

export default Breadcrumb;
