import React from "react";
import {
  IconTarget,
  IconPerson,
  IconPrep,
  IconCalculator,
  IconCalendar,
  IconResources,
  IconPoster
} from "../../svg/Icons";

const icon = (icon) => {
  const icons = {
    "icon-target": <IconTarget />,
    "icon-person": <IconPerson />,
    "icon-prep": <IconPrep />,
    "icon-calculator": <IconCalculator />,
    "icon-calendar": <IconCalendar />,
    "icon-resources": <IconResources />,
    "icon-poster": <IconPoster />
  };

  return icons[icon];
};

export default icon;
