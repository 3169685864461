import React from "react";

// used in the two rout component's render
class ScrollToTop extends React.Component {
  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return this.props.children;
  }
}

export default ScrollToTop;
