import React from "react";
import Helmet from "react-helmet";
import Hero from "../../components/Hero";
import Journey from "../../components/Journey";
import Prefooter from "../../components/Prefooter";
import SignUp from "../../components/SignUp";

const Home = () => (
  <div>
    <Helmet>
      {/* Open Graph Data */}
      <meta property="og:url" content="http://prepare.admission.ucla.edu/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="College Prep Tips | UCLA" />
      <meta property="og:description" content="Tips to help 8th–11th graders prepare for college. Get info on course requirements, paying for college, financial aid, test prep, extracurriculars and more." />
      <meta property="og:image" content="http://prepare.admission.ucla.edu/images/social-horizontal-1200x628.jpg" />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      <meta property="og:image:alt" content="Did somebody say college? Let us help you get there." />
      <meta property="fb:app_id" content="1067749600064544" />

      {/* Twitter Card Data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@UCLAAdmission" />
      
      <link rel="canonical" href="http://prepare.admission.ucla.edu/" />
    </Helmet>
		<Hero />
    <Journey />
    <SignUp />
    <Prefooter />
  </div>
);

export default Home;
