import React from "react";

const SignUp = () => (
  <section id="signup">
    <div className="signup-gradient">
      <h2>Sign up for Tips</h2>
      <div className="text-bubbles">
        <picture>
          <source
            srcSet="/images/text-bubble-signup-large2x.png"
            media="(min-width: 768px)"
            />
          <img
            id="signup-gray-bubble"
            src="/images/text-bubble-signup-small2x.png"
            alt="How do I turn my college dreams into reality?"
            />
          <img
            src="/images/text-bubble-signup-large2x.png"
            className="ie11 ie-dependent"
            alt="How do I turn my college dreams into reality?"
            />
        </picture>
        <img
          id="signup-blue-bubble"
          src="/images/text-bubble-signup-blue2x.png"
          alt="Sign up for College Prep Tips."
        />
      </div>
      <div>
        <a className="gtm-signup-button button" href="https://connect.admission.ucla.edu/register/?id=013bcda6-8b4f-445b-be4e-d25416e5e2b5" rel="noopener noreferrer" target="_blank">Sign up</a>
      </div>
    </div>
  </section>
);

export default SignUp;
