import React from "react";
import { arrayOf, string, shape, func } from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import icon from "../data/grades/seasons-icons.js";

const ContentAccordion = ({ accordionItems, children }) => {
  return (
    <Accordion>
      {accordionItems.map((item, index) => {
        return (
          <AccordionItem key={index}>
            <AccordionItemTitle>
              {item.icon && icon(item.icon)}
              <h2>{item.title}</h2>
              <div className="accordion__arrow"></div>
            </AccordionItemTitle>
            <AccordionItemBody>{children(item)}</AccordionItemBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

ContentAccordion.propTypes = {
  accordionItems: arrayOf(
    shape({
      title: string.isRequired
    })
  ).isRequired,
  children: func.isRequired
};

export default ContentAccordion;
